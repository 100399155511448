import React from 'react'
import { graphql } from 'gatsby'

import DynamicZone from "../components/DynamicZone/DynamicZone";
import SEO from '../components/SEO/SEO'

export default function Home({ data: { strapi: { homepage } }, location }) {

	return (
		<>
			<SEO title="Home" pathname={ location.pathname } />

			{ homepage && homepage.content &&
				<DynamicZone components={ homepage.content } />
			}

		</>
	)
}

export const query = graphql`
	query homepageQuery {
		strapi {
			homepage {
				content {
					__typename
					... on StrapiQuery_ComponentHomepageHomepage {
						id
						homepageTitle
						homepageDescription
						homepageLinkUrl
					}
					... on StrapiQuery_ComponentCommonCallToActionBanner {
						id
						title
						description
						link
						linkLabel
						isExternalLink
					}
					... on StrapiQuery_ComponentCommonLinkStatement {
						id
						linkStatementLinkText
						linkStatementTitle
						linkStatementUrl
					}
					... on StrapiQuery_ComponentCommonStatement {
						statementTitle
						statementSubTitle
					}
					... on StrapiQuery_ComponentCommonSectionLink {
						id
						linkTitle
						linkUrl
					}
				}
			}
		}
	}
`